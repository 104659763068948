<template>
	<div>
		<Title title="稿件预览" :backShow2="true" />


		<div class="Man_content">
			<div class="Man_content_title_box">
				<h4 class="title">{{ $store.state.FillForm.title }}</h4>
				<p class="hint">提示：本平台所发布信息的内容和准确性由提供消息的原单位或组织独立承担完全责任!</p>
				<p class="fillFormTime">{{ $store.state.FillForm.limit_time ? `限时稿件-截稿时间：${limit_time}` : '' }}</p>
			</div>
			<div class="cover_image flex" v-if="shopObj[0].category_id == 4 && FillForm.cover_image != ''">
				<p class="cover_image_title">封面展示：</p>
				<img :src="FillForm.cover_image" alt="">
			</div>
			<div class="cover_image flex" v-if="shopObj[0].category_id == 7 && FillForm.video_url != ''">
				<p class="cover_image_title">视频预览：</p>
				<video :src="FillForm.video_url" controls="controls"></video>
			</div>
			<p v-if="$store.state.FillForm.url" class="Man_content_title">转载链接: <span
					@click="$goLink($store.state.FillForm.url)">{{
			$store.state.FillForm.url }}</span></p>
			<div class="remark_box" v-if="this.$store.state.FillForm.remark">
				备注信息：{{ this.$store.state.FillForm.remark }}
			</div>
			<div v-if="$store.state.FillForm.content" class="Man_content_text"
				v-html="$options.filters.formatTitle(content, verifyArray)">
			</div>

		</div>
		<!-- <div class="Man_content" >
			
		</div> -->

		<SetMenuButNav />
	</div>
</template>

<script>
import Title from '../../components/UserInfoTitle.vue'
import SetMenuButNav from '../../components/SetMenuButNav.vue'
import { mapState } from 'vuex'
import { timeCut } from '../../util/timeCut'
// import { goLink } from '@/util/goLink'
export default {
	mounted() {
		this.content = JSON.parse(JSON.stringify(this.$store.state.FillForm.content))
		// 效验文章是否有敏感字
		this.verifyTest()
		// console.log(this.$store.state.FillForm);
		if (this.$store.state.FillForm.limit_time !== '') {
			this.limit_time = timeCut(this.$store.state.FillForm.limit_time)
		}
	},
	computed: {
		...mapState(['FillForm', 'shopObj']),
	},
	components: {
		Title,
		SetMenuButNav,
	},
	data() {
		return {
			content: '',
			limit_time: '',
			verifyArray: [],
		}
	},
	filters: {
		formatTitle(value, keyword) {
			if (!value) return '';
			// value = value.length > 30 ? value.substr(0, 29) + '...' : value; //标题超过30字符展示...
			if (Array.isArray(keyword)) {
				keyword.forEach((item) => {
					if (item && value.includes(item)) {
						var regex = RegExp('(' + item.replace('\\$1') + ')', 'ig');
						var html = '<span style="color:red;font-size="1em"" >' + item + '</span>';
						value = value.replace(regex, html)
					}
				})
			}
			return value;
		},
	},
	methods: {
		verifyTest() {
			if (this.$store.state.FillForm.content) {
				this.curlGet('/api/index/website').then(res => {
					res.data.data.sensitive_word.forEach((item, index) => {
						this.verifyArray.push(item)
					})
				})
			}
		},

	},
}
</script>

<style lang="scss" scoped>
.cover_image {
	margin: 1rem 0;

	.cover_image_title {
		color: #333;
		font-size: 1.22rem;
		margin-right: 1rem;
	}

	img {
		width: 300px;
	}
	video{
		width: 300px;
	}
}

.Man_content {
	min-height: 100vh;
	padding: 24px 15%;
	box-sizing: border-box;
	position: relative;
	z-index: 999;
	border-radius: 0.83rem;
	background: #ffffff;
	box-shadow: 2px 2px 4px rgba(122, 122, 122, 0.25);
	margin-top: 1rem;
	margin-bottom: 10rem;

	// div {
	// 	font-size: 1.44rem;
	// 	color: #333;

	// }

	.Man_content_title_box {
		border-bottom: 0.08rem solid #aaaaaa;
		padding-bottom: 1rem;
		box-sizing: border-box;

		.title {
			color: #333;
			font-size: 1.8rem;
			text-align: center;
		}

		span {
			overflow: hidden;
		}
	}


	.Man_content_title {
		background: #E1F1FC;
		padding: 1rem 0;
		box-sizing: border-box;
		color: #108CDD;
		font-size: 1.22rem;

		span {
			cursor: pointer;
			color: #333;
			font-size: 1.22rem;
			text-decoration: underline;
		}
	}


}

.keyWord {
	color: red;
}

.fillFormTime {
	color: #EF2626;
	font-size: 1.33rem;
	font-weight: bold;
	text-align: left;
}

.hint {
	color: #EF2626;
	font-size: 1.2rem;
	text-align: center;
}

.remark_box {
	background: #E1F1FC;
	padding: 1rem 0;
	max-height: 10rem;
	overflow-y: auto;
	font-size: 1.22rem;
	box-sizing: border-box;
}

.Man_content_text {
	color: #333;
	line-height: 2rem;

	::v-deep p {
		font-size: 1.33rem !important;
		color: #333;

		strong {
			font-size: 1em !important;
			color: #333;
		}
	}

}
</style>